














































































import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import ListItemFormCard from "@/components/questionnaire/ListItemFormCard.vue";
import FormsHelper from "../../../includes/logic/Forms/FormsHelper";
import { InputSetups } from "../../../mixins/input-setups"
import TariffsTagsHelper from "../../../mixins/TariffsTagsHelper"
import { errorNotification, successNotification } from '@/includes/NotificationService'
import { FormData } from '@/includes/logic/Forms/types'
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import { UseFields } from "piramis-base-components/src/components/Pi"
import { ListItem } from "piramis-base-components/src/components/Pi/components/List/logic/types";
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import ExtendedHelpMessage from "piramis-base-components/src/components/ExtendedHelpMessage.vue";

import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  'components': {
    PermissionsBanner,
    List,
    ListItemFormCard,
    NotAvailableOptionsOverlay,
    PageTitle,
    ExtendedHelpMessage
  },

  'data': () => ({
    ListDirection,
    EntityTypes,
    SitePermissionEnum
  })
})
export default class Questionnaire extends
  Mixins<UseFields, InputSetups, TariffsTagsHelper, FormsHelper>(UseFields, InputSetups, TariffsTagsHelper, FormsHelper) {
  forms: Array<ListItem> = []

  @Watch('$route.params.CHAT_ID')
  async onChatIdChange() {
    await this.updateForms()
  }

  get canChangeConfig() {
    return this.$store.getters.canChangeConfig
  }

  async deleteFormFn(guid: string): Promise<void> {
    await this.deleteForm(this.$store.getters.chatId, guid)
    await this.$store.dispatch("deleteForm", guid)

    this.forms = [ ...this.$store.state.chatState.questionnaire.forms ]
      .map((formItem: FormData) => ({ 'value': formItem }))

    successNotification(this.$t('success').toString())
  }

  async updateForms(): Promise<void> {
    try {
      await this.$store.dispatch("getForms")

      this.forms = [ ...this.$store.state.chatState.questionnaire.forms ]
        .map((formItem: FormData) => ({ 'value': formItem }))
    } catch (error) {
      errorNotification(error)
    }
  }

  async mounted(): Promise<void> {
    this.$baseTemplate.saveButton.hide()

    await this.updateForms()
  }
}
